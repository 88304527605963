<template>
  <PageListLayout
    icon="mdi-map-marker-outline"
    :title="title"
    noFilter
    useBack
    linkBack="/quanlyyeucaukhieunai/yeucau"
  >
    <template slot="side-right">
      <div class="mr-4">
        <v-btn
          depressed
          color="success"
          @click="handleSubmit"
          :loading="btnLoading"
        >
          <v-icon left>
            {{ editing ? "mdi-account-edit" : "mdi-account-plus" }}</v-icon
          >
          {{ editing ? "Cập nhật" : "Thêm mới" }}
        </v-btn>
      </div>
    </template>
    <v-form ref="form">
      <v-row>
        <v-col cols="3">
          <div class="label-form">Công ty</div>
          <v-autocomplete
            v-model="form.customer_id"
            :items="customersList"
            item-text="name"
            item-value="id"
            placeholder="Công ty"
            outlined
            dense
            prepend-inner-icon="mdi-code-tags"
            :rules="required_rule"
            :disabled="edit ? true : false"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Người tiếp nhận</div>
          <v-autocomplete
            v-model="form.nhanvien_id"
            :items="ListEmployee"
            item-text="name"
            item-value="id"
            placeholder="Người tiếp nhận"
            outlined
            dense
            prepend-inner-icon="mdi-account"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Loại yêu cầu</div>
          <v-autocomplete
            v-model="form.type"
            :items="listType"
            item-text="name"
            item-value="id"
            placeholder="Loại yêu cầu"
            outlined
            dense
            prepend-inner-icon="mdi-code-tags"
            :rules="required_rule"
            :disabled="edit ? true : false"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Ngày tiếp nhận</div>
          <DateTimePickerComponent
            v-model="form.date_reception"
            placeholder="Ngày tiếp nhận"
          />
        </v-col>

        <v-col cols="3">
          <div class="label-form">Kênh tiếp nhận</div>
          <v-autocomplete
            v-model="form.reception_channel_type"
            :items="channels"
            outlined
            dense
            item-text="name"
            item-value="id"
            placeholder="Chọn loại yêu cầu"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <div class="label-form">Ngày xử lý</div>
          <DateTimePickerComponent
            v-model="form.processing_date"
            placeholder="Ngày xử lý"
          />
        </v-col>
        <v-col cols="3">
          <div class="label-form">Ngày hoàn thành</div>
          <DateTimePickerComponent
            v-model="form.finish_date"
            placeholder="Ngày hoàn thành"
          />
        </v-col>
        <v-col cols="3">
          <div class="label-form">Trạng thái xử lý</div>
          <v-autocomplete
            v-model="form.status"
            :items="statusList"
            outlined
            dense
            item-text="name"
            item-value="id"
            placeholder="Chọn trạng thái"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="label-form">Nội dung tiếp nhận thông tin</div>
          <v-textarea
            v-model="form.content"
            placeholder="Nội dung tiếp nhận thông tin"
            outlined
            dense
            prepend-inner-icon="mdi-note"
            no-resize
            :rules="required_rule"
            :rows="3"
            hide-details="auto"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <UploadFile v-model="form.documents" :editing="editing" multiple />
        </v-col>
      </v-row>
    </v-form>
  </PageListLayout>
</template>
<script>
import { statusList, listType, channels } from "@/constants/yeucau";
import API from "@/api/yeucau.api";
import KHACHHANG from "@/api/khachhang";
import { getNhanVienKinhDoanh } from "@/api/nhanvien";
import JsonToFromData from "@/utils/JsonToFormData.js";
import { getDanhMucCon } from "@/api/danhmuc.js";
import DateTimePickerComponent from "@/components/Date/date-time-picker";
export default {
  components: {
    DateTimePickerComponent,
  },
  data: () => ({
    channels,
    listType,
    ListEmployee: [],
    customersList: [],
    statusList,
    showPickDate: false,
    show: false,
    edit: false,
    btnLoading: false,
    form: {
      code: null,
      name: null,
      deparment_name: null,
      team_name: null,
      team_code: null,
      order: 0,
      description: null,
      nguyen_nhan: null,
      bien_phap_xu_ly: null,
      nguoi_tiep_nhan: null,
      trang_thai: 2,
    },
    nameRules: [(v) => !!v || "Tên không thể bỏ trống"],
    codeRules: [(v) => !!v || "Mã không thể bỏ trống"],
    required_rule: [(v) => !!v || "Không thể bỏ trống"],
  }),
  computed: {
    editing() {
      return this.$route.params.id && !isNaN(this.$route.params.id)
        ? true
        : false;
    },
    title() {
      return this.editing
        ? "CẬP NHẬT THÔNG TIN yêu cầu"
        : "THÊM THÔNG TIN yêu cầu";
    },
  },
  created() {
    this.btnLoading = true;
    Promise.all([
      this.fetchData(),
      this.getAllEmployee(),
      this.getAllCustomers(),
    ]).finally(() => {
      this.btnLoading = false;
    });
  },
  methods: {
    async fetchData() {
      if (this.editing) {
        const res = await API.getDetail(this.$route.params.id);
        this.form = res;
      }
    },
    async getAllEmployee() {
      try {
        const res = await getNhanVienKinhDoanh();
        this.ListEmployee = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAllCustomers() {
      const res = await KHACHHANG.getAllCustomers();
      this.customersList = res.data;
    },
    async handleSubmit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          if (this.editing) {
            await this.editItem();
          } else {
            await this.addItem();
          }
          this.$router.push({
            path: `/quanlyyeucaukhieunai/yeucau`,
          });
        } finally {
          this.btnLoading = false;
        }
      }
    },
    async addItem() {
      let data = { ...this.form };
      if (data.documents && data.documents.some((x) => x.file)) {
        data.files = data.documents.map((x) => x.file);
        delete data.documents;
      }
      await API.create(data);
    },
    async editItem() {
      let data = { ...this.form };
      if (data.documents && data.documents.some((x) => x.file)) {
        data.docs = data.documents.filter((x) => x.id);
        data.files = data.documents.filter((x) => !x.id).map((x) => x.file);
      }
      delete data.documents;
      delete data.customer;
      delete data.nguoi_tiep_nhan;
      // data = JsonToFromData(data);
      await API.update(this.form.id, data);
    },
  },
};
</script>
